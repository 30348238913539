@tailwind base;
@tailwind components;
@tailwind utilities;

/* GLOBAL CSS */
.topvakantiehuizen-header {
  z-index: 500;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.cookie-notice > div {
  z-index: 9999;
}

/*MANTINE STYLE OVERRIDE*/
.mantine-Accordion-item > div {
  overflow: initial !important;
}

div[role="navigation"] button.mantine-Pagination-active {
  background-color: #228be6;
}

html.dark div[role="navigation"] button {
  color: white;
}

/* Chat Widget */
body .woot-widget-bubble.woot-elements--right {
  left: 20px;
}

@media only screen and (min-width: 667px) {
  body .woot-widget-bubble.woot-elements--right {
    left: 20px;
  }
}

@media only screen and (min-width: 667px) {
  body .woot-widget-holder.woot-elements--right {
    right: unset;
    left: 20px;
  }
}

/*Single Accommodation page CSS*/
.mapContainer {
  height: 500px;
}

.styles_scroll-to-top__2A70v > svg {
  height: 25px;
  width: 40px;
}

/*Header CSS*/
.u-header__topbar .list-inline-item:first-child .u-header__navbar-link {
  padding-top: 1.219rem;
  padding-bottom: 1.219rem;
}

.star-rating-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.stars-selected {
  color: #FB923C;
}

.stars-unselected {
  color: #ccc;
}

header .fab {
  font-size: 1rem;
}

/** navigation bar **/
.custom-padding-nav-break {
  padding: 0.35rem !important;
}

div ul li.active-background-color-nav > a {
  background-color: #d8d8d8 !important;
}

/** Booking page **/
.cursor-pointer-important {
  cursor: pointer !important;
}

div#basicsAccordion {
  z-index: 30;
}

div#page-hero-image > div {
  z-index: -1;
}

@media (max-width: 767.98px) {
  /** Transparent Header **/
  a span.u-header__navbar-brand-text {
    font-size: 1.4rem !important;
  }
}

@media (max-width: 371px) {
  /** Transparent Header **/
  a span.u-header__navbar-brand-text {
    font-size: 1rem !important;
  }
}

/**
 * 2021-11-03 - Start new CSS for new Website
 */
header.u-header {
  z-index: 10;
}

#page-hero-image .homepage-hero-image {
  z-index: -1;
}

.overview-accordion-icon svg {
  margin-bottom: 1px;
}

/**
 * Swiper CSS
 */
.swiper-lazy-preloader {
  margin-left: 0;
  margin-top: 25%;
}

/**
 * Swiper single listing page
 */
.single-listing .swiper-slide img {
  display: block;
  object-fit: cover;
}

.single-listing .swiper-slide {
  width: 100%;
  padding-right: 0;
}

/**
 * Logo
 */
.footer-logo .custom-logo-styling {
  font-size: large;
}

.custom-logo-styling {
  font-weight: 700;
  color: #009ce0;
  font-size: x-large;
}

/**
 * Overview page sidebar filters
 */

.price-filter-accordion-item > div {
  overflow: inherit !important;
}

/**
 * Booking page
 */
ul#booking-guests-form input:disabled {
  cursor: default;
  background-color: #f1f3f5;
  color: #000000;
  opacity: 1;
}

.dark ul#booking-guests-form input:disabled {
  cursor: default;
  background-color: #1f2937;
  color: #ffffff;
  opacity: 1;
}

.dark ul#booking-guests-form button {
  background-color: #1f2937;
  color: #ffffff;
  opacity: 1;
}

.dark .checkout-details-loading-overlay div {
  background-color: #111827;
}

/**
 * Builder.io content page
 */
div.builder-block .builder-text ul {
  list-style: inside;
  padding: 18px;
}

div.builder-block .builder-text li {
  margin-bottom: 5px;
}

div.builder-block .builder-text h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

div.builder-block .builder-text h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

div.builder-block .builder-text h3 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

/**
 * Availability Calendar
 */
.custom-availability-calendar-container {
  width: 100%;
  position: relative;
}

.custom-availability-calendar-container .custom-date-range__day:disabled {
  background-color: #f8f8f8;
  color: #aeb9bf;
  border-radius: 0;
}

.dark .custom-availability-calendar-container .custom-date-range__day:disabled {
  background-color: #111827;
  color: #495057;
  border-radius: 0;
}

.custom-availability-calendar-container .custom-date-range__weekend:not(:disabled) {
  color: #495057;
}

.custom-availability-calendar-container .custom-date-range__day:not(:disabled) {
  font-weight: bold;
}

.dark .custom-availability-calendar-container .custom-date-range__day:not(:disabled) {
  background-color: #1f2937;
  color: #f8f8f8;
  font-weight: bold;
  border-radius: 0;
}

.custom-availability-calendar-container .custom-date-range__day:not(:disabled):hover,
.dark .custom-availability-calendar-container .custom-date-range__day:not(:disabled):hover {
  background-color: rgb(34 197 94);
  color: #ffffff;
}

.custom-availability-calendar-container .custom-date-range__selected:not(:disabled),
.custom-availability-calendar-container .custom-date-range__selected:disabled,
.dark .custom-availability-calendar-container .custom-date-range__selected:not(:disabled),
.dark .custom-availability-calendar-container .custom-date-range__selected:disabled {
  background-color: rgb(34 197 94);
  color: #ffffff;
}

.custom-availability-calendar-container .custom-date-range__in-range:not(:disabled),
.custom-availability-calendar-container .custom-date-range__in-range:disabled,
.dark .custom-availability-calendar-container .custom-date-range__in-range:not(:disabled),
.dark .custom-availability-calendar-container .custom-date-range__in-range:disabled {
  background-color: rgb(74 222 128);
  color: #ffffff;
}

.custom-availability-calendar-container .custom-date-range__first-in-range:not(:disabled),
.custom-availability-calendar-container .custom-date-range__last-in-range:not(:disabled),
.custom-availability-calendar-container .custom-date-range__last-in-range:disabled,
.dark .custom-availability-calendar-container .custom-date-range__first-in-range:not(:disabled),
.dark .custom-availability-calendar-container .custom-date-range__last-in-range:not(:disabled),
.dark .custom-availability-calendar-container .custom-date-range__last-in-range:disabled {
  background-color: rgb(34 197 94);
  color: #ffffff;
}

.dark .custom-availability-calendar-container .custom-date-range__calendar-header-control {
  color: #ffffff;
}

.dark .custom-availability-calendar-container .custom-date-range__calendar-header-control:hover {
  color: #111827;
}

.dark .custom-availability-calendar-container .custom-date-range__month-picker-control {
  color: #ffffff;
}

.dark .custom-availability-calendar-container .custom-date-range__month-picker-control:hover {
  color: #111827;
  background-color: #d8d8d8;
}

.custom-availability-calendar-container .custom-date-range__month-picker-control-active {
  color: #111827;
  background-color: #d8d8d8;
}

.dark .custom-availability-calendar-container .custom-date-range__month-picker-control-active {
  color: #d8d8d8;
  background-color: rgb(34 197 94);
}

.dark .custom-availability-calendar-container .custom-date-range__year-picker-control:not(:disabled) {
  font-weight: bold;
  color: #d8d8d8;
}

.custom-availability-calendar-container .custom-date-range__year-picker-control:not(:disabled) {
  font-weight: bold;
  color: #111827;
}

.custom-availability-calendar-container .custom-date-range__year-picker-control:not(:disabled):hover {
  font-weight: bold;
  color: #ffffff;
  background-color: rgb(34 197 94);
}

.custom-availability-calendar-container .custom-date-range__year-picker-control-active {
  color: #ffffff;
  background-color: rgb(34 197 94);
}

.dark .custom-availability-calendar-container .custom-date-range__year-picker-control:not(:disabled):hover {
  font-weight: bold;
  color: #d8d8d8;
  background-color: rgb(34 197 94);
}

.dark .custom-availability-calendar-container .custom-date-range__year-picker-control-active {
  color: #d8d8d8;
  background-color: rgb(34 197 94);
}

.dark .custom-availability-calendar-container .custom-date-range__calendar-header-level {
  color: #ffffff;
}

.dark .custom-availability-calendar-container .custom-date-range__calendar-header-level:hover {
  color: #111827;
}

.dark .custom-availability-calendar-container .checkout-details-loading-overlay div {
  background-color: #111827;
}

@media only screen and (max-width: 400px) {
  .custom-logo-styling {
    font-size: large;
  }
}

@media only screen and (max-width: 420px) {
  .footer-social-icons {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .single-listing .swiper-slide img {
    display: block;
    height: 450px;
    object-fit: cover;
  }

  .single-listing .swiper-slide {
    width: unset !important;
    padding-right: 10px;
  }
}
